<template>
  <ion-page>
    <ion-content class="ion-no-padding ion-padding-bottom" :fullscreen="true" :scroll-y="false">
      <div class="a-auth full-height">
        <div class="ion-padding a-auth__content-wrapper">
          <h1>Welcome</h1>
          <ion-text color="medium" class="ion-margin-bottom">Create an account</ion-text>

          <form>
            <ion-item class="ion-no-padding ion-padding-top" :style="valid(v.username)">
              <ion-label position="floating">Username</ion-label>
              <ion-input
                v-model="username"
                class="ion-padding-vertical"
                type="text"
                autocomplete="off"
                @ionBlur="onBlur(v.username)"
              ></ion-input>
            </ion-item>
            <ion-item class="ion-no-padding" :style="valid(v.email)">
              <ion-label position="floating">E-mail</ion-label>
              <ion-input
                v-model="email"
                class="ion-padding-vertical"
                autocomplete="username"
                name="email"
                type="email"
                @ionBlur="onBlur(v.email)"
              ></ion-input>
            </ion-item>
            <ion-item class="ion-no-padding" :style="valid(v.password)">
              <ion-label position="floating">Password</ion-label>
              <ion-input
                v-model="password"
                class="ion-padding-vertical"
                name="password"
                type="password"
                @ionBlur="onBlur(v.password)"
              ></ion-input>
            </ion-item>
            <p v-if="v.password.$dirty && v.password.$invalid " class="invalid" >Password should be at least 8 characters long</p>
            <ion-item class="a-auth__consent ion-no-padding" lines="none">
              <ion-checkbox
                :checked="consent"
                class="ion-margin-start"
                slot="start"
                @ion-change="consent = $event.detail.checked"
              ></ion-checkbox>
              <ion-label class="ion-padding-vertical">
                I agree to
                <a class="link pointer" @click="openTerms('terms')" >App Terms</a>
                and
                <a class="link pointer" @click="openTerms('policy')" >Privacy Policy</a>
              </ion-label>
            </ion-item>

            <div v-if="isLoading" class="load-wrap ion-text-center">
              <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              <p>Loading...</p>
            </div>
            <div v-else>
              <ion-button :disabled="isFormInvalid" class="--default a-auth__button ion-margin-vertical" @click="register">Sign up</ion-button>

              <div class="a-auth__note d-flex ion-align-items-center ion-no-margin">
                <p class="center">Do you have account?</p>
                <router-link tag="ion-text" class="ion-no-padding ion-margin-start" color="primary" fill="clear" to="/app/login">Sign in</router-link>
              </div>
            </div>
          </form>
          <div class="version">version: {{version}}</div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
  import useVuelidate from '@vuelidate/core'
  import { required, email, minLength } from "@vuelidate/validators"
  import { presentToast } from '@/plugins/w/ionic-toast/index'
  import {mapGetters} from 'vuex'
  import {modalController} from '@ionic/vue'
  import TermsModal from '@/plugins/w@screen/register/terms-modal.vue'


  export default {

    data() {
      return {
        version: process.env.VUE_APP_VERSION,
        username: '',
        email: '',
        password: '',
        consent: false,
        v: useVuelidate()
      }
    },

    validations: {
      username: { required },
      email: { required, email },
      password: { required, minLength: minLength(8) }
    },
    computed: {
      isFormInvalid() {
        return this.v.username.$invalid || this.v.email.$invalid || this.v.password.$invalid || !this.consent
      },
      ...mapGetters('wAuth', ['isLoading']),

    },

    methods: {
      async register() {
        const isValid = await this.v.$validate()
        if (!isValid)
          return

        if (!this.consent)
          return presentToast('You must agree to App Terms and Privancy Policy.', 'danger')

        try {
          await this.$store.dispatch('wAuth/signup', { username: this.username,  email: this.email, password: this.password })
          presentToast('You have been successfully registered.', 'success')
          this.username = null
          this.email = null
          this.password = null
          this.$router.push({ path: '/app/country-list' })
        } catch (error) {
          console.error(error.response)
          presentToast(error.response.data.error, 'danger')
        }
      },

      valid(input) {
        return input.$dirty && input.$invalid
          ? { '--border-color': 'var(--highlight-color-invalid)' }
          : {}
      },

      onBlur(element) {
        element.$touch()
      },

      async openTerms(type) {
        const modal = await modalController.create({
          component: TermsModal,
          componentProps: { type: type },
        })
        return modal.present()
      }
    }
  }
</script>
<style lang="sass" scoped>
.link
  color: rgb(26, 87, 208)

.version
  color: rgba(0,0,0, 0.3)
  font-size: 12px
.invalid
  margin: 3px 0 0 0
  font-size: 12px
  color: red
  text-align: left
.pointer
  cursor: pointer
</style>
