
  import useVuelidate from '@vuelidate/core'
  import { required, email, minLength } from "@vuelidate/validators"
  import { presentToast } from '@/plugins/w/ionic-toast/index'
  import {mapGetters} from 'vuex'
  import {modalController} from '@ionic/vue'
  import TermsModal from '@/plugins/w@screen/register/terms-modal.vue'


  export default {

    data() {
      return {
        version: process.env.VUE_APP_VERSION,
        username: '',
        email: '',
        password: '',
        consent: false,
        v: useVuelidate()
      }
    },

    validations: {
      username: { required },
      email: { required, email },
      password: { required, minLength: minLength(8) }
    },
    computed: {
      isFormInvalid() {
        return this.v.username.$invalid || this.v.email.$invalid || this.v.password.$invalid || !this.consent
      },
      ...mapGetters('wAuth', ['isLoading']),

    },

    methods: {
      async register() {
        const isValid = await this.v.$validate()
        if (!isValid)
          return

        if (!this.consent)
          return presentToast('You must agree to App Terms and Privancy Policy.', 'danger')

        try {
          await this.$store.dispatch('wAuth/signup', { username: this.username,  email: this.email, password: this.password })
          presentToast('You have been successfully registered.', 'success')
          this.username = null
          this.email = null
          this.password = null
          this.$router.push({ path: '/app/country-list' })
        } catch (error) {
          console.error(error.response)
          presentToast(error.response.data.error, 'danger')
        }
      },

      valid(input) {
        return input.$dirty && input.$invalid
          ? { '--border-color': 'var(--highlight-color-invalid)' }
          : {}
      },

      onBlur(element) {
        element.$touch()
      },

      async openTerms(type) {
        const modal = await modalController.create({
          component: TermsModal,
          componentProps: { type: type },
        })
        return modal.present()
      }
    }
  }
