<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <h3 v-if="type=='terms'"
            slot="start"
            class="ion-no-margin ion-margin-start">
          App Terms
        </h3>
        <h3 v-else
            slot="start"
            class="ion-no-margin ion-margin-start">
          Privacy Policy
        </h3>
        <ion-buttons slot="end">
          <ion-button icon-only @click="close">
            <ion-icon :icon="closeOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <p v-if="type=='terms'">
        All rights reserved. No part of this application may be reproduced, distributed, or&nbsp;transmitted in any
        form or&nbsp;by any means, including photocopying, recording, or&nbsp;other electronic or&nbsp;mechanical
        methods, without the prior written permission of&nbsp;AICO.
        For permission requests, please write to <a href="mailto:adminAOPC@aicolympic.org"><span class="mail">adminAOPC@aicolympic.org</span></a>
      </p>
      <p v-else>
        Application stores email and username only for purpose of providing "Collection" functionality.
        No data are provided to 3rd parties. Storing all data within the application is in compliance with GDPR.
        For full text go to <a href="https://aicolympic.org/privacy-policy/"><span class="mail">https://aicolympic.org/privacy-policy/</span></a>
      </p>
    </ion-content>
  </ion-page>
</template>
<script>
  import { closeOutline } from 'ionicons/icons'
  import { modalController } from '@ionic/vue'

  export default {
    props: ['type'],
    data() {
      return {
        closeOutline
      }
    },

    methods: {
      close() {
        modalController.dismiss()
      }
    }
  }
</script>
<style lang="sass" scoped>
h3
  font-weight: 700
p
  line-height: 1.6
a .mail
  color: black
  text-decoration: underline
</style>
